import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './App.css'
import Home from './components/Home'
import { News } from './pages/News'
import AppProvider from './providers/AppProvider'
import { Error } from './pages/Error'
import { AllNews } from './pages/AllNews'
import { AllProducts } from './pages/AllProducts'
import { Products } from './pages/Products'
import { Contact } from './pages/Contact'
import { Certifications } from './pages/Certifications'
import { Customers } from './pages/Customers'
import { AboutUs } from './pages/AboutUs'
import { Privacy } from './pages/Privacy'
import { HowItsMade } from './pages/HowItsMade'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: 'news/:newsId',
    element: <News />,
  },
  {
    path: 'all-news/',
    element: <AllNews />,
  },
  {
    path: 'all-products/',
    element: <AllProducts />,
  },
  {
    path: 'products/:productId/',
    element: <Products />,
  },
  {
    path: 'contact/',
    element: <Contact />,
  },
  {
    path: 'certifications/',
    element: <Certifications />,
  },
  {
    path: 'customers/',
    element: <Customers />,
  },
  {
    path: 'AboutUs/',
    element: <AboutUs />,
  },
  {
    path: 'privacy/',
    element: <Privacy />,
  },
  {
    path: 'HowItsMade/',
    element: <HowItsMade />,
  },
])

function App() {
  return (
    <div className="App">
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </div>
  )
}

export default App
